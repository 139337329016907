<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import PeriodicTable from '@/components/Fantasy/periodicTable'
import ElementLogs from '@/components/Fantasy/elementsLogs'
import userService from '../../../helpers/userService';
import errorHandler from '../../../helpers/errorHandler';

/**
 * Starter component
 */
export default {
  async created(){
    this.role = userService.methods.userRole();
    await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
        this.profileData = this.$store.getters['student/getStudent'];
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err)
      console.log("No items");
    });
    await this.$store.dispatch('fantasy/getMyChances',this.$route.params.student).then(()=>{}).catch(err =>{
      console.log('No chances')
    })
    await this.$store.dispatch('fantasy/getMyElements',this.$route.params.student).then(()=>{}).catch(err =>{
      console.log('No Elements')
    })
  },
  data() {
    return {
      title: "My Fantasy",
      items: [
        {
          text: "Dr.Micheal Labib",
          href: "/",
        },
        {
          text: "Fantasy",
          href: "/my-fantasy",
        },
        {
          text: "My-fantasy",
          active: true,
        },
      ],
      profileData: null,
      role: null,
      comment: null,
    };
  },
  computed:{
    chances(){
      return this.$store.getters['fantasy/allChances'];
    },
    elements(){
      return this.$store.getters['fantasy/allElements'];
    },
    student(){
      return this.$store
    }
  },
  methods:{
    async addChance(){
      await this.$store.dispatch('fantasy/addChance', {stud_id:this.$route.params.student,chance_comment:(this.comment != '')?this.comment:null}).then(async (res)=>{
        errorHandler.methods.successful('You added new chance successfully !')
      }).catch( err =>{
        errorHandler.methods.errorMessage(err);
      })
    },
    async removeChance(){
      await this.$store.dispatch('fantasy/removeChance', this.$route.params.student).then(async (res)=>{
        errorHandler.methods.successful('You removed a chance successfully !')
      }).catch( err =>{
        errorHandler.methods.errorMessage(err);
      })
    },
    // async addElement(){
    //     //TODO:: make add element directly to admin only
    //   await this.$store.dispatch('fantasy/useChance',this.$route.params.student).then(async (res)=>{
    //     errorHandler.methods.successful('You add an element successfully !')
    //     await this.$store.dispatch('fantasy/getMyElements', this.$route.params.student)
    //   }).catch( err =>{
    //     errorHandler.methods.errorMessage(err);
    //   })
    // },
    async removeElement(){
      await this.$store.dispatch('fantasy/removeElement', this.$route.params.student).then(async (res)=>{
        errorHandler.methods.successful('You removed an element successfully !')
        await this.$store.dispatch('fantasy/getMyElements', this.$route.params.student)
      }).catch( err =>{
        errorHandler.methods.errorMessage(err);
      })
    },
  },
  components: {
    Layout,
    PageHeader,
    PeriodicTable,
    ElementLogs,
  },
};
</script>

<template>
  <Layout>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet"> 
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <h1 class="boujee-text text-center" style="padding-bottom: 2.5rem !important;">Chemistry Fantasy</h1>
      </div>
      <div class="col-12 col-md-4">
        <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-lg h-md-250 position-relative" style="background-color: #fafafa;">
          <div class="col p-3 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-primary" v-if="profileData">{{ profileData.user.name }}'s Fantasy</strong>
            <h3 class="mb-1">Chances: {{ chances }}</h3>
            <p class="card-text mb-1">Use chance to get new elements.</p>
            <div class="form-group mb-3" v-if="this.role == 0">
              <label for="Phoneid">Comment</label>
              <input
                  class="form-control"
                  v-model="comment"
                  type="text"
                  id="commentId"
                  placeholder="Enter chance comment"
              />
            </div>
            <b-button v-if="this.role == 0" variant="success" pill class="btn btn-block" @click="addChance">Add chance</b-button>
            <b-button v-if="this.role == 0" variant="danger" pill class="btn btn-block" :disabled="chances == 0" @click="removeChance">Remove chance</b-button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-lg h-md-250 position-relative" style="background-color: #fafafa;">
          <div class="col p-3 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-success" v-if="profileData">{{ profileData.user.name }}'s Fantasy</strong>
            <h3 class="mb-1">Elements: {{ elements.length }}</h3>
            <p class="card-text mb-1">Use chance to get new elements.</p>
            <!-- <b-button variant="success" pill class="btn btn-block" @click="addElement">Add element</b-button> -->
          </div>
        </div>
      </div>
    </div>
    <PeriodicTable :elements="elements"/>
    <ElementLogs :elements="elements" />
  </Layout>
</template>

<style scoped>
.boujee-text {
  --bg-size: 400%;
  --color-one: #42d392;
  --color-two: #647eff;
  font-family: 'Pacifico', cursive;
  font-size: clamp(2rem, 15vmin, 5rem);
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

</style>